import React from "react"
import { isArray } from "lodash"

export default ({ items, styleP }) => {
  if (items == null) return <div />

  if (!isArray(items)) {
    return (
      <p key={Math.random() * 100} style={styleP}>
        {items}
      </p>
    )
  }

  return (
    <>
      {items.map((item, i) => (
        <p key={i} style={styleP}>
          {item.text}
        </p>
      ))}
    </>
  )
}
