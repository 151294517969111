import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import ContactFooter from "../components/ContactForm/ContactFooter"
import P from "../components/HelperComponents/PrismicParagraphHelper"
import SEO from "../components/seo"

const WhyPage = () => {
  const data = useStaticQuery(graphql`
    query whyPage {
      prismicWhy {
        data {
          titel {
            text
          }
          meta_description {
            text
          }
          block {
            block_text {
              text
            }
            block_titel {
              text
            }
            icon {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          hero_img {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
      prismicBasicInfo {
        data {
          orange
          main_color_two
        }
      }
    }
  `)

  const { titel, block, hero_img, meta_description } = data.prismicWhy.data
  const { orange, main_color_two } = data.prismicBasicInfo.data

  return (
    <>
      <SEO title={titel.text} description={meta_description.text} />
      <div
        style={{
          paddingTop: 50,
          textAlign: "center",
          backgroundColor: main_color_two,
        }}
        className="borderradius-div-bottom"
      >
        <h1 className="resp-header">{titel.text}</h1>
        <section style={{ maxWidth: 450, margin: "0 auto 75px auto" }}>
          <Img fluid={hero_img.localFile.childImageSharp.fluid} />
        </section>

        <main style={{ paddingBottom: 10 }}>
          {block.map((item, i) => {
            const padding =
              i % 2 === 0 ? "padding-waarom-left" : "padding-waarom-right"

            const title = (
              <h3 key={`title-${i}`} style={{ color: orange }}>
                {item.block_titel.text}
              </h3>
            )
            const p = (
              <P
                key={`paragraph-${i}`}
                items={item.block_text.text}
                styleP={{ margin: 0 }}
              />
            )
            const section = (
              <section
                key={`section-${i}`}
                className={padding}
                style={{ display: "inline-block", maxWidth: 600 }}
              >
                {[title, p]}
              </section>
            )

            const icon = (
              <section
                key={`img-${i}`}
                style={{ width: 100, display: "inline-block" }}
              >
                <Img
                  className="resp-hide"
                  fluid={item.icon.localFile.childImageSharp.fluid}
                />
              </section>
            )

            const html = []

            if (i % 2 === 0) {
              html.push(icon)
              html.push(section)
            } else {
              html.push(section)
              html.push(icon)
            }

            return (
              <section
                key={i}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  maxWidth: 1800,
                  textAlign: i % 2 === 0 ? "left" : "right",
                  marginBottom: 100,
                }}
                className="waarom-padding"
              >
                {html}
              </section>
            )
          })}
        </main>
      </div>
      <ContactFooter />
    </>
  )
}

export default WhyPage
